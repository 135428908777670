import validate from '../utils/validationRules'
import { formatMoneyWithSign } from '../utils/money'
import { formatNumber } from '../utils/number'
import { getCurrencySign } from './currency'

const TYPE_MONEY = 'money'

const TYPES = {
  integer: '',
  [TYPE_MONEY]: getCurrencySign(),
  percent: '%',
}

function getMetricWithSign(value, type) {
  if (type === TYPE_MONEY) {
    return formatMoneyWithSign(value)
  }

  return `${value}${TYPES[type]}`
}

export function formatDisplayAmount(value) {
  if (validate.isUndefined(value) || validate.isNull(value)) {
    return ''
  }

  return formatNumber(value, 2, '.', ' ')
}

export function formatDisplayAmountWithSign(value, type = TYPE_MONEY) {
  if (validate.isUndefined(value) || validate.isNull(value)) {
    return ''
  }

  const formattedAmount = formatDisplayAmount(value)
  const formattedAmountWithSign = getMetricWithSign(formattedAmount, type)

  return formattedAmountWithSign
}
