<script lang="ts">
  export let logoLink: string
  export let projectName: string
  export let template: object

  const logoEmpty = projectName.charAt(0).toLowerCase()
</script>

<div class="logo-overlay" style="background-color: {template?.logoOverlayBackgroundColor}">
  {#if logoLink}
    <div class="logo-image" style="background-image: url({logoLink});"></div>
  {:else}
    <div
      class="logo-empty"
      style="
        color: {template?.logoEmptyColor};
        background-color: {template?.logoEmptyBackgroundColor};
      "
    >
      {logoEmpty}
    </div>
  {/if}
</div>

<style>
  .logo-overlay {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -25px;
    right: 20px;
    padding: 2px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .logo-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }

  .logo-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 20px;
    font-weight: 500;
    color: rgba(33, 33, 33, .25);
    text-transform: uppercase;
    background-color: #ade3fb;
    border-radius: 50%;
  }
</style>
