export default function serializeParams(params, prefix) {
  const result = []
  Object.keys(params).forEach(key => {
    const arg = prefix
      ? prefix + '[' + key + ']'
      : key

    const v = params[key]
    const isObject = v !== null && typeof v === 'object'
    result.push(isObject ? serializeParams(v, arg) : encodeURIComponent(arg) + '=' + encodeURIComponent(v))
  });
  return result.join('&')
}

export function getQueryParamsAsObject(searchString) {
  return searchString
    .split('&')
    .reduce((prev, curr) => {
      let p = curr.split('=')
      prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1])

      return prev
    }, {})
}
