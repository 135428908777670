<script lang="ts">
  export let text: string = ''
  export let type: string = 'submit'

  $: cssVariables = Object.entries($$props)
    .filter(([key]) => key.startsWith('--'))
    .reduce((css, [key,value])=>`${ css }${ key }: ${ value };`, '')
</script>

<button type={type} style="{cssVariables}" on:click>{text}</button>

<style>
  button {
    margin: var(--margin, 0 auto);
    padding: var(--padding, 8px 15px);
    font-weight: var(--font-weight, 500);
    font-size: var(--font-size, 14px);
    line-height: 20px;
    color: var(--color, #000);
    background-color: var(--background-color, transparent);
    border-style: solid;
    border-width: var(--border-width, 1px);
    border-color: var(--border-color, rgba(0, 0, 0, 0.2));
    border-radius: var(--border-radius, 10px);
    text-align: center;
    outline: none;
    cursor: pointer;
  }

  button:hover,
  button:focus {
    border-color: rgba(0, 0, 0, 1);
  }

  button:active {
    color: #FFF;
    background-color: #212121;
    border-color: rgba(0, 0, 0, 1);
  }
</style>
