<script lang="ts">
  import { onMount, createEventDispatcher } from 'svelte'

  import BaseInput from '../BaseInput.svelte'
  import BaseButton from '../BaseButton.svelte'

  import throttle from '../../../lib/utils/throttle'

  export let content: string = ''

  onMount(async () => {
    if (!content) {
      return
    }

    console.log('content', content)
    connectFontInHTML()
  })

  const dispatch = createEventDispatcher()
  const handleInput = throttle(connectFontInHTML, 500)

  function connectFontInHTML() {
    insertFontToHTML()
  }

  function getFontSource() {
    return `https://fonts.googleapis.com/css?family=${content}:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,&amp;display=swap&amp;subset=cyrillic`;
  }

  function insertFontToHTML() {
    const link = document.createElement('link')
    link.href = getFontSource()
    link.rel = 'stylesheet'

    document.getElementsByTagName('head')[0].appendChild(link)
  }

  function handleSubmit() {
    dispatch('submit', { font: content })
  }
</script>

<div class="font-container">
  <h2>Шрифты</h2>

  <BaseInput
    bind:value={content}
    label={'Подключенный'}
    name={'font'}
    on:input={handleInput}
  />
  <BaseButton on:click={handleSubmit} text="Сохранить" />
</div>

<style>
  h2 {
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000;
  }

  .font-container :global(input) {
    margin-bottom: 10px;
  }
</style>
