<script lang="ts">
  import { onMount, createEventDispatcher } from 'svelte'

  import throttle from '../../../lib/utils/throttle'

  import BaseInput from '../BaseInput.svelte'
  import BaseUploader from '../BaseUploader.svelte'
  import BaseSwitcher from '../BaseSwitcher.svelte'
  import BaseButton from '../BaseButton.svelte'

  export let content: Object<any>

  let form: Object<any> = {
    proportionalPreview: false,
    aspectRatio: '1/1',
    objectFit: 'contain',
    imageEmpty: 'https://goods.dobro.market/svg/smile.svg',
    imageOverlay: '#E1F5FE',
    borderRadius: '5',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%), inset 0px -3px 0px rgb(0 0 0 / 10%)',
    boxShadowHover: '0px 4px 4px rgb(0 0 0 / 25%), inset 0px -3px 0px rgb(0 0 0 / 10%)',
    ownerTitle: true,
    cardTitleFontWeight: '400',
    cardTitleFontSize: '12px',
    cardTitleColor: '#000000',
    ownerLogo: true,
    logoEmptyColor: 'rgba(33, 33, 33, .25)',
    logoEmptyBackgroundColor: '#ade3fb',
    logoOverlayBackgroundColor: '#ade3fb',
    productTitle: true,
    cardDescriptionFontWeight: '400',
    cardDescriptionFontSize: '16px',
    cardDescriptionLineHeight: '20px',
    cardDescriptionColor: '#000000',
    cardDescriptionLineClamp: '2',
    cardDescriptionMarginBottom: '5px',
    priceTitle: true,
    cardAmountFontWeight: '400',
    cardAmountFontSize: '16px',
    cardAmountColor: '#000000',
    textAlign: 'left',
    currencySign: '\u20BD',
    withoutPriceTitle: 'Купить',
    cardAmountBackgroundColor: '',
    cardAmountPadding: '',
    cardAmountBorderWidth: '',
    cardAmountBorderColor: '',
    cardAmountBorderRadius: '',
  }

  onMount(async () => {
    form = { ...form, ...content }
  })

  const dispatch = createEventDispatcher()

  const handleInput = throttle(() => {
    console.log('handleInput')
    dispatch('update', { cards: { ...form } })
  }, 500)

  function handleUpload(data) {
    console.log(data)
  }

  function handleSubmit() {
    dispatch('submit', { cards: { ...form } })
  }
</script>

<div class="form-container">
  <h2>Карточки</h2>

  <BaseSwitcher
    bind:checked={form.proportionalPreview}
    label="Пропорциональное превью"
    name="proportional-preview"
    on:change={handleInput}
  />
  {#if form.proportionalPreview}
    <BaseInput
      bind:value={form.aspectRatio}
      label="Соотношение сторон, aspect-ratio"
      name="aspect-ratio"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.objectFit}
      label="Заполнение, object-fit"
      name="object-fit"
      on:input={handleInput}
    />
  {/if}

  <BaseUploader
    bind:value={form.imageEmpty}
    label="image-empty"
    name="image-empty"
    on:upload={$event => {
      form.imageEmpty = $event.detail.link
      handleInput()
    }}
  />
  <BaseInput
    bind:value={form.imageOverlay}
    label="image-overlay, background-color"
    name="image-overlay"
    on:input={handleInput}
  />
  <BaseInput
    bind:value={form.borderRadius}
    label="border-radius"
    name="border-radius"
    on:input={handleInput}
  />
  <BaseInput
    bind:value={form.backgroundColor}
    label="background-color"
    name="background-color"
    on:input={handleInput}
  />
  <BaseInput
    bind:value={form.boxShadow}
    label="box-shadow"
    name="box-shadow"
    on:input={handleInput}
  />
  <BaseInput
    bind:value={form.boxShadowHover}
    label="box-shadow, hover"
    name="box-shadow-hover"
    on:input={handleInput}
  />

  <BaseSwitcher
    bind:checked={form.ownerTitle}
    label="Продавец"
    name="owner-title"
    on:change={handleInput}
  />
  {#if form.ownerTitle}
    <BaseInput
      bind:value={form.cardTitleFontWeight}
      label="card-title, font-weight"
      name="card-title-font-weight"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardTitleFontSize}
      label="card-title, font-size"
      name="card-title-font-size"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardTitleColor}
      label="card-title, color"
      name="card-title-color"
      on:input={handleInput}
    />
  {/if}

  <BaseSwitcher
    bind:checked={form.ownerLogo}
    label="Логотип продавца"
    name="owner-logo"
    on:change={handleInput}
  />
  {#if form.ownerLogo}
    <BaseInput
      bind:value={form.logoEmptyColor}
      label="logo-empty, color"
      name="logo-empty-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.logoEmptyBackgroundColor}
      label="logo-empty, background-color"
      name="logo-empty-background-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.logoOverlayBackgroundColor}
      label="logo-overlay, background-color"
      name="logo-overlay-background-color"
      on:input={handleInput}
    />
  {/if}

  <BaseSwitcher
    bind:checked={form.productTitle}
    label="Название товара"
    name="product-title"
    on:change={handleInput}
  />
  {#if form.productTitle}
    <BaseInput
      bind:value={form.cardDescriptionFontWeight}
      label="card-description, font-weight"
      name="card-description-font-weight"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardDescriptionFontSize}
      label="card-description, font-size"
      name="card-description-font-size"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardDescriptionLineHeight}
      label="card-description, line-height"
      name="card-description-line-height"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardDescriptionColor}
      label="card-description, color"
      name="card-description-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardDescriptionLineClamp}
      label="card-description, -webkit-line-clamp"
      name="card-description-webkit-line-clamp"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardDescriptionMarginBottom}
      label="card-description, margin-bottom"
      name="card-description-margin-bottom"
      on:input={handleInput}
    />
  {/if}

  <BaseSwitcher
    bind:checked={form.priceTitle}
    label="Ценник"
    name="price-title"
    on:change={handleInput}
  />
  {#if form.priceTitle}
    <BaseInput
      bind:value={form.cardAmountFontWeight}
      label="card-amount, font-weight"
      name="card-amount-font-weight"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardAmountFontSize}
      label="card-amount, font-size"
      name="card-amount-font-size"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardAmountColor}
      label="card-amount, color"
      name="card-amount-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.textAlign}
      label="text-align"
      name="text-align"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.currencySign}
      label="Валюта"
      name="currency-sign"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.withoutPriceTitle}
      label="Текст при отсутствии цены"
      name="without-price-title"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardAmountBackgroundColor}
      label="card-amount, background-color"
      name="card-amount-background-color"
      placeholder="none"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardAmountPadding}
      label="card-amount, padding"
      name="card-amount-padding"
      placeholder="0px"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardAmountBorderWidth}
      label="card-amount, border-width"
      name="card-amount-border-width"
      placeholder="0px"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardAmountBorderColor}
      label="card-amount, border-color"
      name="card-amount-border-color"
      placeholder="transparent"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.cardAmountBorderRadius}
      label="card-amount, border-radius"
      name="card-amount-border-radius"
      placeholder="0px"
      on:input={handleInput}
    />
  {/if}

  <BaseButton
    text="Сохранить"
    on:click={handleSubmit}
  />
</div>

<style>
  h2 {
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000;
  }

  .form-container :global(.container) {
    margin-bottom: 10px;
  }

  .form-container :global(.cs-switcher-label) {
    width: 100%;
    margin-bottom: 10px;
  }
</style>
