const TRANSACTION_CONTEXT = {
  currency: {
    code: 'RUB',
    sign: '₽',
  },
}

export function getTransactionContext() {
  return TRANSACTION_CONTEXT
}
