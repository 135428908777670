import ky from 'ky';
import { API_URL } from './env/.env';
const publicClient = ky.extend({
    prefixUrl: `${API_URL}dobromarket/`
});
const protectedClient = ky.extend({
    prefixUrl: `${API_URL}dobromarket/protected/`
});
function getToken() {
    return localStorage.getItem('token');
}
export async function imageUpload(formData) {
    const token = getToken();
    return protectedClient.post(`image/upload`, {
        body: formData,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }).json();
}
export async function fetchBills(payload) {
    try {
        const data = await publicClient.get(`bills/?${payload}`).json();
        return data;
    }
    catch (error) {
        console.error(error);
    }
}
export async function fetchMakers(payload) {
    try {
        const data = await publicClient.get(`makers/?${payload}`).json();
        return data;
    }
    catch (error) {
        console.error(error);
    }
}
export function fetchBillCategories() {
    return publicClient.get(`bill-categories`).json();
}
export function fetchMakerCategories() {
    return publicClient.get(`maker-categories`).json();
}
// Получение списка шаблонов:
// GET /v1/dobromarket/protected/templates
export function allTemplates() {
    const token = getToken();
    return protectedClient.get(`templates`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).json();
}
// Получение шаблона по идентификатору:
// GET /v1/dobromarket/protected/template?{params}
export function getTemplate(params) {
    const token = getToken();
    return protectedClient.get(`template?${params}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).json();
}
export function getTemplateByName(name) {
    const token = getToken();
    return publicClient.get(`template?name=${name}`).json();
}
// Создание шаблона:
// POST /v1/dobromarket/protected/template
// Пример запроса:
// {
//     "name": "name",
//     "font": "font",
//     "cards": {},
//     "grid": {},
//     "search": {}
// }
export function createTemplate(name) {
    const token = getToken();
    const json = {
        name,
        font: '',
        cards: {},
        grid: {},
        search: {},
    };
    return protectedClient.post(`template`, {
        json,
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).json();
}
// Изменение шаблона:
// PUT /v1/dobromarket/protected/template/{id}
// Пример запроса:
// {
//     "name": "name",
//     "font": "font",
//     "cards": {},
//     "grid": {},
//     "search": {}
// }
export function updateTemplate(data) {
    const token = getToken();
    const json = Object.assign({}, data);
    return protectedClient.put(`template/${data.id}`, {
        json,
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).json();
}
// Удаление шаблона:
// DELETE /v1/dobromarket/protected/template/{id}
export function removeTemplateById(id) {
    const token = getToken();
    return protectedClient.delete(`template/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).json();
}
