<script lang="ts">
  import { onMount } from 'svelte'
  import { Link, useNavigate } from 'svelte-navigator'

  import TemplatesController from '../components/templates/TemplatesController.svelte'

  import {
    allTemplates,
    createTemplate,
    updateTemplate,
    removeTemplateById
  } from '../api'

  const navigate = useNavigate()

  let templates: array = []

  onMount(async () => {
    await fetchTemplates()
  })

  async function fetchTemplates() {
    try {
      templates = await allTemplates()
    } catch (error) {
      console.error(`${error.name}: ${error.message}`)
      navigate('/')
    }

    console.log(templates)
  }

  async function addTemplate() {
    await createTemplate(name = 'unknown')
    fetchTemplates()
  }

  async function handleUpdate(event: CustomEvent) {
    const template = event.detail
    console.log('template', template)

    await updateTemplate(template)
    fetchTemplates()
  }

  async function handleRemove(event: CustomEvent) {
    const template = event.detail
    const templateId = template.id

    await removeTemplateById(templateId)
    fetchTemplates()
  }
</script>

<div class="main-container">
  <div class="templates-container">
    <TemplatesController
      templates={templates}
      on:add={addTemplate}
      on:update={handleUpdate}
      on:remove={handleRemove}
    />
  </div>
</div>

<style>
  .main-container {
    min-height: 100vh;
    background-color: rgba(33, 33, 33, .1);
  }

  .templates-container {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
  }
</style>
