<script lang="ts">
  import { onMount } from 'svelte'

  import validate from '../lib/utils/validationRules'
  import { formatDisplayAmountWithSign } from '../lib/transaction/metric'

  import CardImage from './components/CardImage.svelte'
  import CardLogo from './components/CardLogo.svelte'

  export let card: object
  export let template: object
  export let gridTemplate: object

  const isPartner = card.type === 'PARTNER'
  const PARTNER_AMOUNT_TEXT = 'Купить'

  const INTERNAL_AMOUNT_TEXT = template?.withoutPriceTitle
    ? template.withoutPriceTitle : 'Поддержать'

  $: isShownContent = (() => {
    if (validate.isEmptyObject(template)) {
      return true
    }

    return template?.ownerTitle || template?.ownerLogo || template?.productTitle || template?.priceTitle
  })()

  $: isShownOwnerLogo = (() => {
    if (validate.isEmptyObject(template)) {
      return true
    }

    return template?.ownerLogo
  })()

  $: isShownOwnerTitle = (() => {
    if (validate.isEmptyObject(template)) {
      return true
    }

    return template?.ownerTitle
  })()

  $: isShownProductTitle = (() => {
    if (validate.isEmptyObject(template)) {
      return true
    }

    return template?.productTitle
  })()

  $: isShownPriceTitle = (() => {
    if (validate.isEmptyObject(template)) {
      return true
    }

    return template?.priceTitle
  })()

  function getTariff() {
    const { billTariffs } = card
    if (validate.isNull(billTariffs) || validate.isEmptyArray(billTariffs)) {
      return null
    }

    const [ tariff ] = billTariffs
    if (validate.isNotObject(tariff)) {
      return null
    }

    return tariff
  }

  function formatPrice(value, separator = ' ') {
    const preparedValue = String(value).replace(/\s/g, '').replace(/,/g, '.')
    return preparedValue.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
  }

  function getFormattedAmount(amount) {
    const formattedAmount = formatPrice(amount / 100)

    if (template?.currencySign) {
      return `${formattedAmount} ${template.currencySign}`
    }

    const formattedDisplayAmount = formatDisplayAmountWithSign(formattedAmount)

    return formattedDisplayAmount
  }

  $: displayAmount = (() => {
    if (isPartner) {
      if (validate.isNull(card.amount)) {
        return template?.withoutPriceTitle
          ? template.withoutPriceTitle : PARTNER_AMOUNT_TEXT
      }

      return getFormattedAmount(card.amount)
    }

    const tariff = getTariff()

    if (validate.isNull(tariff)) {
      return template?.withoutPriceTitle
        ? template.withoutPriceTitle : INTERNAL_AMOUNT_TEXT;
    }

    if (validate.isNull(tariff.amount)) {
      return template?.withoutPriceTitle
        ? template.withoutPriceTitle : INTERNAL_AMOUNT_TEXT;
    }

    return getFormattedAmount(tariff.amount)
  })()

  $: displayProductTitle = (() => {
    if (card.name) {
      return card.name
    }

    return card.description ? card.description : ''
  })()
</script>

<a
  href={card.payLink}
  class="card"
  target="_blank"
  rel="noopener noreferrer"
  style="
    background-color: {template?.backgroundColor};
    border-radius: {template?.borderRadius}px;
    box-shadow: {template?.boxShadow}
  "
>
  <CardImage
    imageLink={card.billImageLink}
    {template}
  />

  {#if isShownContent}
    <div class="card-content">
      {#if isShownOwnerLogo}
        <CardLogo
          logoLink={card.projectLogoLink}
          projectName={card.projectName}
          {template}
        />
      {/if}
      {#if isShownOwnerTitle}
        <div
          class="card-title"
          style="
            font-weight: {template?.cardTitleFontWeight};
            font-size: {template?.cardTitleFontSize};
            color: {template?.cardTitleColor};
          "
        >
          {card.projectName}
        </div>
      {/if}
      {#if isShownProductTitle}
        <div
          class="card-description"
          style="
            font-weight: {template?.cardDescriptionFontWeight};
            font-size: {template?.cardDescriptionFontSize};
            line-height: {template?.cardDescriptionLineHeight};
            color: {template?.cardDescriptionColor};
            -webkit-line-clamp: {template?.cardDescriptionLineClamp};
            margin-bottom: {template?.cardDescriptionMarginBottom};
            height: {gridTemplate?.cardHeightFixed ? '39px' : 'auto'};
          "
        >
          {@html displayProductTitle}
        </div>
      {/if}
      {#if isShownPriceTitle}
        <div
          class="card-amount"
          style="
            font-weight: {template?.cardAmountFontWeight};
            font-size: {template?.cardAmountFontSize};
            color: {template?.cardAmountColor};
            text-align: {template?.textAlign};
            background-color: {template?.cardAmountBackgroundColor};
            padding: {template?.cardAmountPadding};
            border-width: {template?.cardAmountBorderWidth};
            border-color: {template?.cardAmountBorderColor};
            border-radius: {template?.cardAmountBorderRadius};
          "
        >
          {displayAmount}
        </div>
      {/if}
    </div>
  {/if}
</a>

<style>
  .card {
    overflow: hidden;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -3px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .card:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -3px 0px rgba(0, 0, 0, 0.1);
  }

  .card-content {
    position: relative;
    padding: 17px 20px;
  }

  .card-title {
    margin: 0 0 10px;
    padding: 0 40px 0 0;
    font-size: 12px;
    line-height: 15px;
    color: #212121;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .card-description {
    max-height: 39px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #212121;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .card-description :global(*) {
    margin: 0;
  }

  .card-amount {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #212121;
    border: 0px solid transparent;
  }
</style>
