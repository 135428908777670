<script lang="ts">
  export let items = []
  export let activeTabValue = 1

  const handleClick = tabValue => () => (activeTabValue = tabValue)
</script>

<ul class="tabs-container">
{#each items as item}
  <li
    class="tab-item"
    class:active={activeTabValue === item.value}
    on:click={handleClick(item.value)}
  >
    {@html item.icon}
  </li>
{/each}
</ul>
<div class="content-container">
  {#each items as item}
    {#if activeTabValue == item.value}
      <svelte:component
        this={item.component}
        content={item.content}
        on:update
        on:submit
      />
    {/if}
  {/each}
</div>

<style>
  .tabs-container {
    margin: 0;
    padding: 16px 40px 16px 0;
    list-style: none;
  }

  .tab-item {
    display: flex;
    cursor: pointer;
  }

  .tab-item:not(:last-child) {
    margin-bottom: 40px;
  }

  .tab-item :global(svg) {
    fill: #374957;
  }

  .tab-item.active :global(svg) {
    fill: #FF5172;
  }

  .content-container {
    flex-grow: 1;
    padding: 16px;
    overflow: hidden;
    box-sizing: border-box;
  }
</style>
