<script lang="ts">
  import { onMount, onDestroy } from 'svelte'

  import Spinner from '../components/Spinner.svelte'
  import Menu from '../components/Menu.svelte'
  import SearchFixed from '../components/SearchFixed.svelte'
  import SearchInput from '../components/SearchInput.svelte'

  import Catalog from '../catalog/Catalog.svelte'

  import serializeParams from '../lib/utils/url'
  import validate from '../lib/utils/validationRules'

  import {
    fetchMakerCategories,
    fetchBillCategories,
    fetchBills,
    fetchMakers,
    getTemplateByName
  } from '../api'

  import { BILLS_TYPE, MAKERS_TYPE, FILTER_ALL } from '../const'

  export let template: Object<any> = {}

  // TODO: внедрить после презентации
  // interface Catalog {
  //   [page: number]: 1,
  //   [count: number]: 1,
  //   [list: array]: [],
  //   [loading: boolean]: false,
  //   [countPerPage: number]: 12,
  //   [searchText: string]: '',
  //   [filterBy: string]: 'all',
  //   [lastDataChunk: array]: [],
  //   [type: string]: 'bills',
  // }

  let categories: array = []
  let catalog: object = {
    page: 1,
    items: [],
    loading: false,
    searchText: '',
    filterBy: FILTER_ALL,
    type: BILLS_TYPE,
    lastDataChunk: [],
    countPerPage: 0,
    offset: 0,
    categories: [],
  }
  let iframeHeight, isLastDataChunk

  $: {
    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onScroll)
    isLastDataChunk = catalog.lastDataChunk.length < catalog.countPerPage
  }

  onMount(async () => {
    try {
      await loadTemplate()
      await loadData()
    } catch (error) {
      console.error(error)
    }
  })

  onDestroy(() => {
    window.removeEventListener('scroll', onScroll)
    window.removeEventListener('resize', onScroll)
  })

  function getPayload() {
    const { value: search } = document.getElementById('search') || { value: catalog.searchText }
    const { value: categories } = document.getElementById('categories') || {}
    const { value: projects } = document.getElementById('projects') || {}

    const templateId = template.id || ''
    console.log('getPayload::templateId', templateId, validate.isNumber(templateId))

    const getParams = Object.assign(
      (validate.isNotEmptyString(search) && { search }),
      (validate.isNotEmptyString(categories) && { categories }),
      (validate.isNotEmptyString(projects) && { projects }),
      (validate.isNumber(templateId) && { templateId }),
      { offset: catalog.offset },
    )

    return serializeParams(getParams)
  }

  function resetData() {
    catalog.items = []
    catalog.offset = 0
  }

  function resetFilters() {
    catalog.searchText = ''
    catalog.filterBy = FILTER_ALL
  }

  async function loadData() {
    catalog.loading = true

    await loadBills()

    catalog.loading = false
  }

  async function loadBills() {
    const payload = getPayload()
    const bills = await fetchBills(payload)
    const categories = await fetchBillCategories()

    catalog.items.push(...bills.result)
    catalog.lastDataChunk = bills.result
    catalog.countPerPage = bills.countPerPage
    catalog.offset = bills.offset
    catalog.categories = categories
  }

  async function onChangeTab(event: CustomEvent) {
    resetData()
    resetFilters()

    const tabCode = event.detail
    catalog.type = tabCode

    await loadData()
  }

  async function onSearch(event: CustomEvent) {
    resetData()

    const searchPhrase = event.detail
    catalog.searchText = searchPhrase

    await loadData()
  }

  async function onFilter(event: CustomEvent) {
    resetData()

    const filterData = event.detail
    catalog.filterBy = filterData

    await loadData()
  }

  async function onScroll(event: CustomEvent) {
    if (catalog.loading) {
      return
    }

    if (isLastDataChunk) {
      return;
    }

    if (template?.grid?.showMore) {
      return;
    }

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset || 0
    const offset = scrollTop + window.innerHeight + 20
    const height = document.documentElement.offsetHeight

    if (offset >= height) {
      await loadData()
    }
  }

  async function loadTemplate() {
    const { value: templateName } = document.getElementById('template') || {}

    if (validate.isUndefined(templateName)) {
      return
    }

    try {
      template = await getTemplateByName(templateName)
    } catch (e) {
      console.error(`${e.name} ${e.message}`)
    }
  }
</script>

<div
  class="container"
  style="font-family: {template?.font}"
  on:scroll={onScroll}
>
  {#if template?.search?.selectedTemplate}
    {#if template.search.selectedTemplate === 'Шаблон №1'}
      <SearchFixed
        searchText={catalog.searchText}
        {template}
        on:search={onSearch}
      />
    {:else}
      <SearchInput
        searchText={catalog.searchText}
        {template}
        on:search={onSearch}
      />
    {/if}
  {/if}

  <Catalog
    items={catalog.items}
    type={catalog.type}
    isFilled={isLastDataChunk}
    {template}
    on:show-more={loadData}
  />

  {#if catalog.loading}
    <Spinner {template} />
  {/if}
</div>

<style>
  .container {
    width: 100%;
  }
</style>
