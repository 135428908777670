<script lang="ts">
  import LetterCaseIcon from '../../assets/icons/fi-rr-letter-case.svg'
  import PlayingCardsIcon from '../../assets/icons/fi-rr-playing-cards.svg'
  import GridAltIcon from '../../assets/icons/fi-rr-grid-alt.svg'
  import SearchIcon from '../../assets/icons/fi-rr-search.svg'

  import FontTab from './tabs/Font.svelte'
  import CardTab from './tabs/Card.svelte'
  import GridTab from './tabs/Grid.svelte'
  import SearchTab from './tabs/Search.svelte'

  import TabsContainer from './TabsContainer.svelte'

  export let template: object = {}

  let items = [
    {
      value: 1,
      icon: LetterCaseIcon,
      component: FontTab,
      content: template.font,
    },
    {
      value: 2,
      icon: PlayingCardsIcon,
      component: CardTab,
      content: template.cards,
    },
    {
      value: 3,
      icon: GridAltIcon,
      component: GridTab,
      content: template.grid,
    },
    {
      value: 4,
      icon: SearchIcon,
      component: SearchTab,
      content: template.search,
    }
  ]
</script>

<div class="settings-container">
  <TabsContainer
    {items}
    on:update
    on:submit
  />
</div>

<style>
  .settings-container {
    width: 400px;
    min-width: 400px;
    min-height: 100vh;
    display: flex;
    padding: 20px;
    background-color: rgba(33, 33, 33, .1);
    box-sizing: border-box;
  }
</style>
