function isEmail(email) {
  const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;

  return emailPattern.test(email);
}

function required(val) {
  return isNotNull(val) && isNotUndefined(val) && isNotEmpty(val);
}

function isNull(val) {
  return val === null;
}

function isNotNull(val) {
  return !isNull(val);
}

function isUndefined(val) {
  return val === undefined;
}

function isNotUndefined(val) {
  return !isUndefined(val);
}

function isEmpty(val) {
  return isEmptyString(val) || isNan(val) || isEmptyArray(val) || isEmptyObject(val);
}

function isNotEmpty(val) {
  return !isEmpty(val);
}

function isNan(val) {
  return isNumber(val) && isNaN(val);
}

function isNotNan(val) {
  return isNumber(val) && !isNaN(val);
}

function isString(val) {
  return typeof val === 'string';
}

function isNotString(val) {
  return !isString(val);
}

function isEmptyString(val) {
  return isString(val) && val === '';
}

function isNotEmptyString(val) {
  return isString(val) && val.length > 0;
}

function isEmptyArray(val) {
  return isArray(val) && val.length === 0;
}

function isNotEmptyArray(val) {
  return isArray(val) && val.length > 0;
}

function isFixedLengthArray(val, length) {
  return isNotEmptyArray(val) && val.length === length;
}

function isObject(val) {
  return isNotNull(val) && isNotUndefined(val) && isNotString(val) && isNotArray(val) && isNotNumber(val);
}

function isNotObject(val) {
  return !isObject(val);
}

function isEmptyObject(val) {
  return isObject(val) && Object.keys(val).length === 0;
}

function isNotEmptyObject(val) {
  return isObject(val) && Object.keys(val).length > 0;
}

function isArray(val) {
  return Array.isArray(val);
}

function isNotArray(val) {
  return !isArray(val);
}

function isObjectWithFields(object, fields) {
  return isObject(object) && fields.every(field => object.hasOwnProperty(field));
}

function isNumber(val) {
  return typeof val === 'number';
}

function isNotNumber(val) {
  return !isNumber(val);
}

function isPositiveInt(val) {
  return isNumber(val) && Number.isInteger(val) && val > 0;
}

function isPositiveFloat(val) {
  return isNumber(val) && val % 1 !== 0 && val > 0;
}

function isPositiveNumber(val) {
  return isPositiveFloat(val) || isPositiveInt(val);
}

export function isStringIncludingSubstring(val, substring) {
  return val.indexOf(substring) !== -1;
}

export function isStringNotIncludingSubstring(val, substring) {
  return val.indexOf(substring) === -1;
}

function isObjectValuesValidByCallback(object, callback) {
  if (isUndefined(callback)) {
    throw new Error('Pass the callback function as the second argument');
  }

  return isObject(object) && Object.values(object).every(callback);
}

const validationRules = {
  required,
  isNull,
  isNotNull,
  isUndefined,
  isNotUndefined,
  isEmpty,
  isNotEmpty,
  isEmptyString,
  isNotEmptyString,
  isEmptyArray,
  isNotEmptyArray,
  isFixedLengthArray,
  isEmptyObject,
  isNotEmptyObject,
  isArray,
  isNotArray,
  isEmail,
  isString,
  isNotString,
  isObject,
  isNotObject,
  isObjectWithFields,
  isNumber,
  isNotNumber,
  isNan,
  isNotNan,
  isPositiveInt,
  isPositiveFloat,
  isPositiveNumber,
  isStringIncludingSubstring,
  isStringNotIncludingSubstring,
  isObjectValuesValidByCallback,
};

export default validationRules;
