<script lang="ts">
  import { generateRandomString } from '../../lib/utils/string.ts'

  export let label: string = 'Название поля'
  export let placeholder: string = 'Значение в поле'
  export let name: string = ''
  export let value: string = ''

  const inputId: string = generateRandomString()
</script>

<div class="container">
  <input bind:value id={inputId} type="text" {name} {placeholder} on:input />
  <label for={inputId}>{label}</label>
</div>

<style>
  .container {
    position: relative;
    flex-grow: 1;
  }

  label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000;
  }

  input {
    width: 100%;
    padding: 28px 10px 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 10px;
    outline: none;
    transition: all .2s linear;
    box-sizing: border-box;
  }

  input::placeholder {
    color: rgba(0, 0, 0, .25)
  }
</style>
