<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  import BaseButton from '../components/templates/BaseButton.svelte'
  import MediaQuery from '../components/MediaQuery.svelte'
  import Masonry from '../components/Masonry.svelte'
  import BillCard from './BillCard.svelte'
  import MakerCard from './MakerCard.svelte'

  import { BILLS_TYPE } from '../const'

  export let items: array
  export let type: string
  export let template: Object<any>
  export let isFilled: boolean

  const dispatch = createEventDispatcher()
  const isBills = type === BILLS_TYPE

  const loaderButtonPositions = {
    'left': 'flex-start',
    'center': 'center',
    'right': 'flex-end',
  }

  $: justifyContent = loaderButtonPositions[template?.grid?.loaderButtonAlign]
</script>

<div
  class="catalog-container"
  style="max-width: {template?.grid?.containerMaxWidth}px;"
>
  <MediaQuery query="(max-width: 570px)" let:matches>
    {#if matches}
      <Masonry
        gridGap={template?.grid?.gridGapPhones || '12px'}
        colWidth={'minmax(Min(30vw, 100%), 1fr)'}
        items={items}
      >
        {#each items as card, index (index)}
          <BillCard card={card} template={template?.cards} gridTemplate={template?.grid} />
        {/each}
      </Masonry>
    {/if}
  </MediaQuery>

  <MediaQuery query="(min-width: 571px)" let:matches>
    {#if matches}
      <Masonry
        gridGap={template?.grid?.gridGap || '20px'}
        colWidth={`minmax(Min(${template?.grid?.mixWidthToTransform || '12em'}, 100%), 1fr)`}
        items={items}
      >
        {#each items as card, index (index)}
          <BillCard card={card} template={template?.cards} gridTemplate={template?.grid} />
        {/each}
      </Masonry>
    {/if}
  </MediaQuery>

  {#if template?.grid?.showMore && !isFilled}
    <div class="loader-button" style="justify-content: {justifyContent}">
      <BaseButton
        text={template?.grid?.loaderButtonText}
        --font-weight={template?.grid?.loaderButtonFontWeight}
        --font-size={template?.grid?.loaderButtonFontSize}
        --color={template?.grid?.loaderButtonFontColor}
        --background-color={template?.grid?.loaderButtonColor}
        --border-color={template?.grid?.loaderButtonBorderColor}
        --border-width={template?.grid?.loaderButtonBorderSize}
        --border-radius={template?.grid?.loaderButtonBorderRadius}
        --margin={template?.grid?.loaderButtonMargin}
        --padding={template?.grid?.loaderButtonPadding}
        on:click={() => dispatch('show-more')}
      />
    </div>
  {/if}
</div>

<style>
  .catalog-container {
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
  }

  @media screen and (min-width: 32rem) {
    .catalog-container {
      max-width: 500px;
    }
  }

  @media screen and (min-width: 481px) {
    .catalog-container {
      max-width: 980px;
    }
  }

  .loader-button {
    display: flex;
  }

  /*.catalog-container {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 32rem) {
    .catalog-container {
      display: block;
    }
  }*/
</style>
