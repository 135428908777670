export function formatNumber(value, decimals = 3, decimalsSeparator = '.', thousandSeparator = '') {
  const numberAsArray = value.toString().split('.')
  const int = numberAsArray[0]
  const formattedInt = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)

  if (numberAsArray.length > 1 && decimals !== 0) {
    const dec = numberAsArray[1].substr(0, decimals)
    return `${formattedInt}${decimalsSeparator}${dec}`
  }

  return formattedInt
}
