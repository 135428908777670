<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { generateRandomString } from '../../lib/utils/string.ts'

  import { imageUpload } from '../../api'

  export let label: string = 'Название поля'
  export let placeholder: string = 'Значение в поле'
  export let name: string = ''
  export let value: string = ''

  const LIMIT_SIZE = 5
  const dispatch = createEventDispatcher()
  const inputId: string = generateRandomString()

  function isInvalidSize(file) {
    const fileSize = file.size / 1024 / 1024
    return fileSize > LIMIT_SIZE
  }

  function handleChange($event: CustomEvent) {
    const files = $event.target.files || $event.dataTransfer.files

    if (!files.length) {
      return
    }

    if (isInvalidSize(files[0])) {
      return
    }

    uploadImage(files[0])
  }

  async function uploadImage(file) {
    let formData = new FormData();
    formData.append('file', file);

    try {
      const data = await imageUpload(formData)
      dispatch('upload', data)
    } catch (error) {
      console.error(`${error.name}: ${error.message}`);
    }
  }
</script>

<div class="container">
  <label for={inputId}>
    <div class="input">{value}</div>
    <div class="label">{label}</div>
    <input
      bind:value
      id={inputId}
      type="file"
      accept="image/*"
      {name}
      {placeholder}
      on:change={handleChange}
    />
  </label>
</div>

<style>
  .container {
    position: relative;
    flex-grow: 1;
  }

  .label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000;
  }

  input {
    display: none;
  }

  .input {
    width: 100%;
    padding: 28px 10px 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #2F80ED;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 10px;
    outline: none;
    transition: all .2s linear;
    box-sizing: border-box;
    cursor: pointer;
  }

  .input::placeholder {
    color: rgba(0, 0, 0, .25)
  }
</style>
