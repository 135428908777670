import { getTransactionContext } from './context'

export function getCurrency() {
  return getTransactionContext().currency
}

export function getCurrencySign() {
  return getCurrency().sign
}

export function getCurrencyCode() {
  return getCurrency().code
}
