import validate from './validationRules'
import { formatNumber } from './number'
import { getCurrencySign } from '../transaction/currency'

export function formatMoneyWithSign(value, formatParams = { decimals: null, decimalsSeparator: '.', thousandSeparator: ' ' }) {
  if (validate.isUndefined(value) || validate.isNull(value)) {
    return ''
  }

  const totalDecimals = getTotalDecimals(formatParams.decimals)
  const formattedNumber = formatNumber(value, totalDecimals, formatParams.decimalsSeparator, formatParams.thousandSeparator)

  const currentSign = getCurrencySign()

  return `${formattedNumber} ${currentSign}`
}

function getTotalDecimals(customDecimals) {
  let totalDecimals = 2

  if (validate.isNotNull(customDecimals)) {
    totalDecimals = customDecimals
  }

  return totalDecimals
}
