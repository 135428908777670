<script>
  import { useNavigate, useLocation } from 'svelte-navigator'

  const navigate = useNavigate()
  const location = useLocation()

  const token = localStorage.getItem('token')

  $: if (!token) {
    navigate('/', {
      state: { from: $location.pathname },
      replace: true,
    })
  }
</script>

{#if token}
  <slot />
{/if}

