<script lang="ts">
  import { generateRandomString } from '../../lib/utils/string.ts'

  export let label: string = 'Название поля'
  export let name: string = ''

  export let options: array = []
  export let value: string = ''

  let inputId: string = generateRandomString()
</script>

<div class="container">
  <select bind:value {name} id={inputId} on:change>
    {#each options as option, i}
      <option value={option}>{option}</option>
    {/each}
  </select>
  <label for={inputId}>{label}</label>
</div>

<style>
  .container {
    position: relative;
    flex-grow: 1;
    padding: 28px 10px 10px;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 10px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000;
  }

  select {
    width: 100%;
    margin-left: -4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #2F80ED;
    border: none;
    outline: none;
    transition: all .2s linear;
    box-sizing: border-box;
  }

  select::placeholder {
    color: rgba(0, 0, 0, .25)
  }
</style>
