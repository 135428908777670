<script lang="ts">
  import { onMount } from 'svelte'
  import { useNavigate } from 'svelte-navigator'

  import validate from '../lib/utils/validationRules'

  import MainView from './Main.svelte'
  import TemplateController from '../components/templates/TemplateController.svelte'

  import {
    getTemplate,
    updateTemplate
  } from '../api'

  export let templateId

  const navigate = useNavigate()

  let template: object = {}

  onMount(async () => {
    await fetchTemplateById()
  })

  async function fetchTemplateById() {
    const preparedId = Number(templateId)

    if (isNaN(preparedId)) {
      navigate('/templates')
    }

    try {
      template = await getTemplate(`id=${preparedId}`)
      console.log(template)
    } catch (e) {
      console.error(`${e.name} ${e.message}`)
      navigate('/templates')
    }
  }

  function getPayload($event: CustomEvent) {
    const { font, cards, grid, search } = $event.detail

    const payload = Object.assign(
      { ...template },
      (validate.isNotUndefined(font) && { font }),
      (validate.isNotUndefined(cards) && { cards }),
      (validate.isNotUndefined(grid) && { grid }),
      (validate.isNotUndefined(search) && { search }),
    )

    return payload
  }

  function handleUpdate($event: CustomEvent) {
    template = getPayload($event)
  }

  async function handleSubmit($event: CustomEvent) {
    await updateTemplate(getPayload($event))
    fetchTemplateById()
  }
</script>

<div class="main-container">
  {#if template.name}
    <TemplateController {template} on:update={handleUpdate} on:submit={handleSubmit} />
    <MainView {template} />
  {/if}
</div>

<style>
  .main-container {
    display: flex;
  }
</style>
